import { FC } from 'react';

import { SessionProvider } from 'next-auth/react';

import { ChatProvider } from '@apps/chat/shared/multilabel/src/ChatProvider';
import type { AppProps } from '@dxp-next';

const ZakelijkApp: FC<AppProps> = ({ Component, pageProps: { session, ...pageProps }, router }) => {
  return (
    <SessionProvider session={session} basePath={`${router.basePath}/api/auth`}>
      <ChatProvider>
        <Component {...pageProps} />
      </ChatProvider>
    </SessionProvider>
  );
};

export default ZakelijkApp;
